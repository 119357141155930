import * as React from 'react';
import Seo from '../../components/seo';
import Layout from '../../components/layout';
import { useEffect, useState } from 'react';
import Fingerprint2 from '@fingerprintjs/fingerprintjs';


const DebugPage = () => {
  const [result, setResult] = useState(null);

  useEffect(() => {
    const loadFingerprint = async () => {
      const fpComponents = await Fingerprint2.getPromise();
      setResult(fpComponents);
    };

    if (!result) {
      setTimeout(loadFingerprint, 500);
    }
  }, [result]);

  return (
    <Layout>
      <h1>FingerprintJS Version 2</h1>
      <p className="text-sm italic text-gray-500">
        Note: newer version of FingerprintJS is not giving as much information as V2, CoverYourTracks.eff.org is also
        using V2
      </p>
      {
        result &&
        <pre>
          { JSON.stringify(result, null, 2) }
        </pre>
      }
    </Layout>
  );
};

export const Head = () => <Seo title="Fingerprint debug" />
export default DebugPage;